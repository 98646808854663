html {
    min-width: 360px;
    scroll-behavior: smooth;
    background-color: #edf2f7;
  }
  
  body {
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
  #outerwrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .editor-class {
    border: 1px solid black;
    min-height: 150px;
    max-height: 420px;
    resize: both;
    overflow-y: scroll;
    background-color: white;
  }
  