.rdw-editor-main ol {
    margin-left: 0;
    padding-left: 2.5em;
    list-style: decimal;
    margin-top: -2em;
    margin-bottom: -2em;
  }

  .rdw-editor-main ol li {
    margin-bottom: -1em;
  }

 

